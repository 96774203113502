import React from 'react';
import './styles/Experience.css';

function Experience() {
  return (
    <section className="experience" id="experience">
      <h2>Experiences</h2>
      <div className="experience-cards">
        <div className="experience-card">
          <h3>California Department of Toxic Substances</h3>
          <p><strong>Solutions Developer Intern (SAAS ArcGIS)</strong> | May 2024 - Present</p>
          <ul>
            <li>Refactored and modularized a Python-based ETL pipeline for the IndoorsTest project, automating the extraction of ArcGIS Online feature layer data and insertion into SQL Server, increasing code reusability and reducing redundancy across 10+ spatial datasets.</li>
            <li>Transformed indoor cubicle layouts across two facilities using Python, ArcGIS Pro, and interactive data visualizations, boosting workstation reservation rates by 90% and ensuring spatial accuracy through rigorous testing.</li>
            <li>Developed an automated metadata compliance system with ArcGIS API and Python to flag incomplete records and notify stakeholders, streamlining maintenance workflows and improving data integrity across thousands of geospatial entries.</li>
            <li>Enhanced situational awareness by upgrading a public-facing ArcGIS Online experience map with live Senate and County data for emergency fire and traffic tracking, enabling stakeholders to respond more effectively in real-time.</li>
          </ul>
        </div>
        <div className="experience-card">
          <h3>Sac State Enrollment Management and Student Services</h3>
          <p><strong>Student Assistant</strong> | April 2022 – August 2024</p>
          <ul>
            <li>Resolved technical issues related to student enrollment data, managing tickets in EAB and developing dashboards in Excel and Tableau.</li>
            <li>Created and refined data queries to analyze enrollment patterns, supporting university stakeholders with comprehensive reporting.</li>
            <li>Earned consecutive nominations for Student Employee of the Year in 2023 and 2024 for outstanding contributions.</li>
          </ul>
        </div>
        <div className="experience-card">
          <h3>Student Service Center</h3>
          <p><strong>Student Assistant</strong> | June 2023 – August 2023</p>
          <ul>
            <li>Assisted students with financial aid and registration-related inquiries, providing exceptional customer service and support.</li>
            <li>Handled a minimum of 30 calls daily, ensuring prompt resolution of student concerns.</li>
            <li>Mastered financial aid processes within a rigorous 5-day training period, demonstrating adaptability and quick learning.</li>
            <li>Developed communication and problem-solving skills while managing high-pressure situations effectively.</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Experience;
